import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@outlier-spa/loading';
import { ImageCut } from 'components';
import { axios } from '@outlier-spa/fetch';
import { useApp } from 'context';
import { IPicture, IPosition } from 'interfaces';

export const New: React.FC = () => {
	const loadingRef = useRef<React.ElementRef<typeof Loading>>(null);
	const [picture, setPicture] = useState<IPicture>();
	const [count, setCount] = useState(0);
	const { user } = useApp();
	const getNextPicture = useCallback(() => {
		loadingRef.current?.setActive(true);
		axios.get<IPicture>(`picture/${user?.email}`).then(({data}) => {
			console.log(data);
			data.positions = data.positions?.map((position: IPosition) : IPosition=> {
				return {
					from: position.from,
					to: position.to,
					x0: parseInt(`${position.x0 / data.width * 100}`),
					y0: parseInt(`${position.y0 / data.height * 100}`),
					x1: parseInt(`${position.x1 / data.width * 100}`),
					y1: parseInt(`${position.y1 / data.height * 100}`),
				};
			}) ?? [];
			setPicture(data);
		}).catch(() => loadingRef.current?.setActive(false));
	}, [user]);

	function onFinish(positions: IPosition[]){
		if (!picture) return;
		const imagePositions = positions.map(pos => ({
			...pos,
			x0: parseInt(`${pos.x0 / 100 * picture.width}`),
			y0: parseInt(`${pos.y0 / 100 * picture.height}`),
			x1: parseInt(`${pos.x1 / 100 * picture.width}`),
			y1: parseInt(`${pos.y1 / 100 * picture.height}`),
		}));
		console.log(imagePositions);
		loadingRef.current?.setActive(true);
		axios.post(`position/${picture?.pictureId}/${user?.email}`, imagePositions).then(({data}) => {
			console.log(data);
			setCount(count + 1);
			setTimeout(() => {
				getNextPicture();
			}, 100);
		}).catch(() => loadingRef.current?.setActive(false));
	}

	function onSkipImage(){
		loadingRef.current?.setActive(true);
		axios.put(`picture/${user?.email}/${picture?.pictureId}`).then(() => {
			setTimeout(() => {getNextPicture();}, 100);
		}).catch(() => loadingRef.current?.setActive(false));
	}

	function onLoadImage(){
		loadingRef.current?.setActive(false);
	}

	useEffect(() => {
		const getCount = () => {
			axios.get<{count: number}>(`user/summary/${user?.email}`).then(({data}) => {
				setCount(data.count);
			});
		}
		setInterval(getCount, 1000*60*10);
		getCount();
	}, [user]);

	useEffect(() => {
		getNextPicture();
	}, [getNextPicture]);

	return (
		<div className='h100'>
			<Loading ref={loadingRef} />
			{picture && (
				<ImageCut picture={picture} onFinish={onFinish} onSkip={onSkipImage} count={count} onLoadImage={onLoadImage} />
			)}
		</div>
	);
};
