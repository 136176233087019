import React, { useEffect, useMemo } from 'react';
import { Card } from '@outlier-spa/component';
import { IPosition } from 'interfaces';

interface IImagePreviewItemProps {
	position: IPosition;
	image: HTMLImageElement;
};

const ImagePreviewItem: React.FC<IImagePreviewItemProps> = ({position, image}) => {
	const [left, top, width, height, scale, scaleWidth] = useMemo(() => {
		const left = - (position.x0 * image.width / 100);
		const top = - (position.y0 * image.height / 100);
		const width = ((position.x1 / 100 * image.width) - left*-1);
		const height = ((position.y1 / 100 * image.height) - top*-1);
		const scale = 50 / height;
		const scaleWidth = width * scale;
		return [left, top, width, height, scale, scaleWidth];
	}, [position, image]);

	useEffect(() => {
	}, [position]);

	return (
		<div className='flex flex-gap-4'>
			<div
				className='relative image-value'
				style={{height: scaleWidth, transform: `scale(${scale})`}}>
				<div
					className='relative o-hidden'
					style={{
						width,
						height,
					}}>
					<img
						alt=''
						src={image.src}
						className='t200'
						style={{
							width: image.width,
							position: 'absolute',
							left,
							top,
						}} />
				</div>
			</div>
			<div className='flex flex-column flex-space code text-12'>
				<span>- {position.from}</span>
				<span>- {position.to}</span>
			</div>
		</div>
	);
};

interface IImagePreviewProps {
	positions?: IPosition[];
	image?: HTMLImageElement;
};

export const ImagePreview: React.FC<IImagePreviewProps> = ({positions, image}) => {

	return (
		<Card className='image-preview flex flex-column flex-vgap-10 overflow-hidden-x pdh-10 relative'>
			{image && positions?.map((position, index) => (
				<ImagePreviewItem key={index} position={position} image={image} />
			))}
		</Card>
	);
};
