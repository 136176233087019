import styled from 'styled-components';

export const StyledLayout = styled.div`
	.inner {
		max-width: 1540px;
    margin-right: auto;
    margin-left: auto;
		padding-left: 20px;
		padding-right: 20px;
	}

	header {
		height: 4.286rem;
		background-color: ${({theme}) => theme.colors.header};
		.ant-input-affix-wrapper {
			border-radius: 18px;
		}
	}
`;
