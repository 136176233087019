import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from './components';
import { AppRoutes } from './routes';
import './services';
import '@outlier-spa/component/lib/esm/style/reset.scss';

const App: React.FC = () => {
	return (
		<Layout>
			<div className='pdv-20 h100'>
				<AppRoutes />
			</div>
		</Layout>
	);
};

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
