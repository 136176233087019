import React, { useState } from 'react';
import {
	ThemeProvider,
	UserPopover,
	Modal,
	Input,
} from '@outlier-spa/component';
import { StyledLayout } from './style';
import { BrowserRouter } from 'react-router-dom';
import { IUser } from 'interfaces';
import { AppProvider } from 'context';

export const Layout: React.FC = ({ children }) => {
	const [user, setUser] = useState<IUser>(JSON.parse(localStorage.getItem('selector:user') || '{}'));
	const [email, setEmail] = useState(user.email);

	function handleSubmitUser(){
		if (email.length >= 10) {
			const newUser = { email };
			setUser(newUser);
			localStorage.setItem('selector:user', JSON.stringify(newUser));
		}
	}

	return (
		<ThemeProvider>
			<BrowserRouter>
				{!user.email && (
					<Modal
						visible
						title='Usuario'
						size='small'
						cancelProps={{visible: false}}
						okText='Iniciar Sesión'
						onSubmit={handleSubmitUser}>
						<div className='pdt-14 pdh-14'>
							<Input
								onChange={(evt) => setEmail(evt.target.value)}
								value={email}
								label='Correo electrónico'
								onPressEnter={handleSubmitUser} />
						</div>
					</Modal>
				)}
				{user.email && (
					<AppProvider user={user}>
						<StyledLayout className='h100 o-hidden flex flex-column ant-fade-enter ant-fade-enter-active'>
							<header className='border-bottom-main'>
								<div className='flex flex-center flex-space h100 pdh-20'>
									<div className='flex flex-center flex-1'>
										<div className='text-16 bold'>
											DrillHoleSelectorSample
										</div>
									</div>
									<UserPopover user={{name: user.email, email: user.email, descriptions: ['Temuco']}} />
								</div>
							</header>
							<div className='layout-content flex-1 overflow-hidden-x t200'>
								{ children }
							</div>
						</StyledLayout>
					</AppProvider>
				)}
			</BrowserRouter>
		</ThemeProvider>
	);
};
