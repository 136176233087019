import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Modal, InputNumber, Button } from '@outlier-spa/component';
import { IPoint } from 'interfaces';

export interface ICutModalRef {
	open: () => void;
	close: () => void;
};

export interface ICutModalProps {
	onSubmit: (position: { from: number, to: number }) => void;
	onCancel: () => void;
};

export const CutModal = React.forwardRef<ICutModalRef, ICutModalProps>(({ onSubmit, onCancel }, ref) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [state, setState] = useState<{from?: number, to?: number}>({});
	const translateRef = useRef<IPoint>({ x: 0, y: 0 });
	const containerRef = useRef<HTMLDivElement|null>(null);
	const onModalMouseMove = useCallback((evt: MouseEvent) => {
		const { movementX, movementY } = evt;
		translateRef.current = {
			x: translateRef.current.x + movementX,
			y: translateRef.current.y + movementY,
		};
		if (containerRef.current) {
			containerRef.current.style.transform = `translate(${translateRef.current.x}px, ${translateRef.current.y}px)`;
		}
	}, []);
	const onModalMouseUp = useCallback(() => {
		document.removeEventListener('mousemove', onModalMouseMove);
		document.removeEventListener('mouseup', onModalMouseUp);
	}, [onModalMouseMove]);

	const onModalMouseDown = useCallback(() => {
		document.addEventListener('mousemove', onModalMouseMove);
		document.addEventListener('mouseup', onModalMouseUp);
	}, [onModalMouseUp, onModalMouseMove]);

	function handleCancel(){
		setState({});
		close();
		onCancel();
	}

	function onAdd(){
		if (state.from !== undefined && state.to !== undefined) {
			if (state.from > state.to) {
				return;
			}
			onSubmit({ from: state.from, to: state.to });
			close();
		}
	}

	function onSave(){
		if (state.from !== undefined && state.to !== undefined) {
			if (state.from > state.to) {
				return;
			}
			onSubmit({ from: state.from, to: state.to });
			close();
			setState({});
		}
	}

	function open(){
		translateRef.current = { x: 0, y: 0 };
		setVisible(true);
	}

	function close(){
		setVisible(false);
	}

	useImperativeHandle(ref, () => ({
		open,
		close,
	}));

	useEffect(() => {
		if (!visible) return;
		let modalTitle: HTMLElement | null = null;
		setTimeout(() => {
			containerRef.current = document.querySelector('.modal-container');
			modalTitle = document.querySelector('.modal-title');
			if (!modalTitle || !containerRef.current) return;
			containerRef.current.style.animation = 'none';
			modalTitle.addEventListener('mousedown', onModalMouseDown);
		}, 300);
		return () => {
			modalTitle?.removeEventListener('mousedown', onModalMouseDown);
			document.removeEventListener('mousemove', onModalMouseMove);
			document.removeEventListener('mouseup', onModalMouseUp);
		}
	}, [visible, onModalMouseDown, onModalMouseMove, onModalMouseUp]);

	const canSave = state.from !== undefined && state.to !== undefined;
	return (
		<Modal
			title='Agregar'
			size='small'
			visible={visible}
			onCancel={close}
			overlayClose={false}
			buttonsVisible={false}>
			<div className='flex flex-center flex-gap-20 pd-14'>
				<InputNumber
					label='* Desde'
					value={state.from}
					autoFocus
					min={0}
					onChange={(value) => setState({...state, from: value as number})}
					className='flex-1'
					style={{width: '100%'}} />
				<InputNumber
					label='* Hasta'
					value={state.to}
					min={0}
					onPressEnter={onAdd}
					messageVisible={canSave && ((state.to as number) < (state.from as number))}
					message='El valor debe ser mayor al valor de "Desde"'
					onChange={(value) => setState({...state, to: value as number})}
					className='flex-1'
					style={{width: '100%'}} />
			</div>
			<div className='pd-14 flex flex-space'>
				<Button onClick={handleCancel} label='Cancelar' bold className='pdh-20' />
				<div className='flex flex-center flex-gap-10'>
					<Button label='Añadir corte' disabled={!canSave} onClick={onAdd} bold className='pdh-20' />
					<Button label='Guardar' disabled={!canSave} onClick={onSave} type='emphasis' bold className='pdh-20' />
				</div>
			</div>
		</Modal>
	);
});
