import React, { useContext } from 'react';
import { IUser } from 'interfaces';

export interface IAppContext {
	user?: IUser;
};

const AppContext = React.createContext<IAppContext>({
	user: { email: '' },
});

export const useApp = () => useContext(AppContext);

export const AppProvider: React.FC<{user: IUser}> = ({ children, user }) => {

	return (
		<AppContext.Provider value={{ user }}>
			{children}
		</AppContext.Provider>
	);
};
