import styled from 'styled-components';

export const StyledImageCut = styled.div`
	user-select: none;
	.hole-image {
		max-width: 100%;
		max-height: calc(100vh - 248px);
	}
	.image-from-to {
		position: absolute;
		background-color: ${({theme}) => theme.colors.emphasis}4D;
		&:hover {
			background-color: ${({theme}) => theme.colors.emphasis}80;
			.remove-cut {
				pointer-events: all;
				opacity: 1;
			}
		}
	}
	.resizers {
		border: 2px dashed ${({theme}) => theme.colors.emphasis};
		.bottom-right {
			position: absolute;
			right: -1px;
			bottom: -1px;
			cursor: nwse-resize;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 10px 10px;
			border-color: transparent transparent ${({theme}) => theme.colors.emphasis} transparent;
		}
	}
	.remove-cut {
		position: absolute;
		right: -0.65rem;
		top: -0.65rem;
		width: 1.3rem;
		height: 1.3rem;
		border-radius: 2rem;
		pointer-events: none;
		opacity: 0;
		background-color: ${({theme}) => theme.colors.danger};
	}
	.current-point {
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: ${({theme}) => theme.colors.emphasis};
		box-shadow: 0 0 0 4px ${({theme}) => theme.colors.emphasis}4D;
		transform: translate(-50%, -50%);
		z-index: 9;
	}
	.image-preview {
		width: 140px;
		.image-value {
			width: 50px;
			transform-origin: top right;
			> div {
				transform: rotate(90deg) translateY(-50px);
				transform-origin: top left;
				position: absolute;
			}
		}
		img {
			pointer-events: none;
			/* transform: rotate(90deg);
			transform-origin: left;
			position: absolute; */
			/* width: 50px;
			min-height: 100%; */
		}
	}
`;
