import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { New } from './new';

export const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path='new' element={<New />} />
			<Route path='' element={<Navigate to='new' />} />
		</Routes>
	);
}
