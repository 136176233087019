import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Card,
	Confirm,
	Input,
	InputNumber,
	Section,
} from '@outlier-spa/component';
import { XBoldOutlined } from '@outlier-spa/icon';
import { StyledImageCut } from './style';
import { IPoint, IPicture, IPosition } from 'interfaces';
import { ImagePreview } from '../image-preview';
import { CutModal } from '../cut-modal';
// import testImg from 'assets/SPD4663_0_11.JPG';

interface IImageRectProps {
	position: IPosition;
	index: number;
	parentRef: React.RefObject<HTMLDivElement>;
	onRemove: (index: number, position: IPosition) => void;
	onUpdate: (index: number, position: IPosition) => void;
};

const apiUrl = process.env.REACT_APP_API_URL;

const ImageRect: React.FC<IImageRectProps> = ({ position: currentPosition, index, parentRef, onRemove, onUpdate }) => {
	const [position, setPosition] = useState<IPosition>(currentPosition);
	const translateRef = useRef<IPoint>({x: 0, y: 0});
	const resizeRef = useRef({ startX: 0, startY: 0 });
	const element = useRef<HTMLDivElement>(null);

	function handleRemove(evt: React.MouseEvent) {
		evt.preventDefault();
		onRemove(index, position);
	}

	function handleMouseMove(ev: MouseEvent) {
		const { movementX, movementY } = ev;
		translateRef.current = {
			x: translateRef.current.x + movementX,
			y: translateRef.current.y + movementY,
		};
		if (element.current){
			element.current.style.transform = `translate(${translateRef.current.x}px, ${translateRef.current.y}px)`;
		}
	}

	function handleMouseUp(){
		if (element.current) {
			element.current.style.transform = '';
		}
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
		if (parentRef.current) {
			const { clientWidth, clientHeight } = parentRef.current;
			const { x, y } = translateRef.current;
			const dx = x / clientWidth * 100;
			const dy = y / clientHeight * 100;
			const newPosition: IPosition = {
				...position,
				x0: position.x0 + dx,
				y0: position.y0 + dy,
				x1: position.x1 + dx,
				y1: position.y1 + dy,
			};
			onUpdate(index, newPosition);
			// setPosition(newPosition);
		}
	}

	function handleClick(){
		translateRef.current = {x: 0, y: 0};
		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	}

	function handleMouseMoveResize(evt: MouseEvent){
		if (!parentRef.current || !element.current) return;
		const { clientX, clientY } = evt;
		const { startX, startY } = resizeRef.current;
		const { clientWidth, clientHeight } = parentRef.current;
		const width = position.x1 - position.x0;
		const height = position.y1 - position.y0;
		const dx = (clientX - startX) / clientWidth * 100;
		const dy = (clientY - startY) / clientHeight * 100;
		if (width + dx > 1) {
			element.current.style.width = `${width + dx}%`;
		}
		if (height + dy > 1) {
			element.current.style.height = `${height + dy}%`;
		}
	}

	function HandleMouseUpResize(){
		document.removeEventListener('mousemove', handleMouseMoveResize);
		document.removeEventListener('mouseup', HandleMouseUpResize);
		if (!element.current) return;
		const style = element.current.style;
		const width = parseFloat(style.width);
		const height = parseFloat(style.height);
		const newPosition: IPosition = {
			...position,
			x1: position.x0 + width,
			y1: position.y0 + height,
		};
		onUpdate(index, newPosition);
		console.log({width, height});
	}

	function handleResizerDown(ev: React.MouseEvent) {
		resizeRef.current = {
			startX: ev.clientX,
			startY: ev.clientY,
		};
		ev.preventDefault();
		ev.stopPropagation();
		document.addEventListener('mousemove', handleMouseMoveResize);
		document.addEventListener('mouseup', HandleMouseUpResize);
	}

	useEffect(() => {
		setPosition(currentPosition);
	}, [currentPosition]);

	return (
		<div
			ref={element}
			className='image-from-to relative code'
			onMouseDown={handleClick}
			style={{
				top: `${position.y0}%`,
				left: `${position.x0}%`,
				width: `${position.x1 - position.x0}%`,
				height: `${position.y1 - position.y0}%`,
			}}>
			<div className='resizers w100 h100'>
				<div className='bottom-right' onMouseDown={handleResizerDown} />
			</div>
			<div
				onClick={handleRemove}
				className='remove-cut t200 flex flex-middle text-10 pointer'
				data-tooltip={`Eliminar`}>
				<XBoldOutlined />
			</div>
		</div>
	);
};

const Point: React.FC<{point: IPoint}> = ({point}) => (
	<div
		className='current-point'
		style={{
			left: `${point.x}%`,
			top: `${point.y}%`,
		}} />
);

function sortPosition(a: IPosition, b: IPosition) {
	if (a.from !== b.from) {
		return a.from - b.from;
	}
	if (Math.abs(a.y0 - b.y0) < 4) {
		return a.x0 - b.x0;
	}
	return a.y0 - b.y0;
}

export interface IImageCutProps {
	count: number;
	picture: IPicture;
	onFinish?: (positions: IPosition[]) => void;
	onSkip?: () => void;
	onLoadImage: () => void;
};

export const ImageCut: React.FC<IImageCutProps> = ({count, picture, onFinish, onSkip, onLoadImage}) => {
	const addModalRef = useRef<React.ElementRef<typeof CutModal>>(null);
	const omitRef = useRef<React.ElementRef<typeof Confirm>>(null);
	const errorRef = useRef<React.ElementRef<typeof Confirm>>(null);
	const wrapperImage = useRef<HTMLDivElement>(null);
	const [point, setPoint] = useState<IPoint|null>(null);
	const [tempPosition, setTempPosition] = useState<IPosition>();
	const [positions, setPositions] = useState<IPosition[]>([]);
	const [image, setImage] = useState<HTMLImageElement>();

	function handleImageClick(evt: React.MouseEvent<HTMLImageElement>){
		const { x, y, width, height } = evt.currentTarget.getBoundingClientRect();
		const pX = (evt.clientX - x) / width * 100;
		const pY = (evt.clientY - y) / height * 100;
		if (point) {
			setPoint(null);
			const position = {
				from: -1,
				to: -1,
				x0: Math.min(point.x, pX),
				y0: Math.min(point.y, pY),
				x1: Math.max(point.x, pX),
				y1: Math.max(point.y, pY),
			};
			setTempPosition(position);
			// setPositions([...positions , position]);
			addModalRef.current?.open();
		} else {
			setTempPosition(undefined);
			setPoint({x: pX, y: pY});
		}
	}

	function handleRemoveRect(index: number){
		setPositions(positions.filter((_, i) => i !== index));
	}

	function handleUpdateRect(index: number, position: IPosition){
		setPositions(positions.map((r, i) => i === index ? position : r).sort(sortPosition));
	}

	function handleOmit(){
		omitRef.current?.open(null, '¿Seguro que desea omitir esta imagen?');
	}

	function handleFinish(){
		if (positions.length === 0) {
			errorRef.current?.open(null, 'Debe realizar al menos un corte para guardar la imagen.');
			return;
		}
		onFinish?.(positions);
		setTempPosition(undefined);
	}

	function handleSubmitModal(pos: { from: number, to: number }){
		if (!tempPosition) {
			return;
		}
		console.log(pos);
		const position: IPosition = {
			...tempPosition,
			from: pos.from,
			to: pos.to,
		};
		setPositions([...positions, position].sort(sortPosition));
		setTempPosition(undefined);
		addModalRef.current?.close();
	}

	function skipImage(){
		onSkip?.();
	}

	function handleCancel(){
		setPoint(null);
		setTempPosition(undefined);
	}

	function handleLoadImage(evt: React.SyntheticEvent<HTMLImageElement>){
		setImage(evt.currentTarget);
		onLoadImage();
	}

	function clean(){
		setPoint(null);
		setTempPosition(undefined);
		setPositions([]);
	}

	useEffect(() => setPositions(picture.positions?.sort(sortPosition) ?? []), [picture]);

	return (
		<StyledImageCut className='h100 o-hidden flex flex-column inner flex-vgap-20 ant-fade-enter ant-fade-enter-active'>
			<Confirm
				ref={errorRef}
				cancelProps={{visible: false}}
				okProps={{ type: 'emphasis' }}
				okText='Aceptar'
				title='Error'
			/>
			<Confirm
				ref={omitRef}
				okText='Omitir imagen'
				title='Advertencia'
				onSubmit={skipImage}
			/>
			<CutModal
				ref={addModalRef}
				onSubmit={handleSubmitModal}
				onCancel={handleCancel} />

			<div className='flex-1 o-hidden flex flex-gap-14'>
				<Card className='flex-1 flex flex-column o-hidden'>
					<div className='flex flex-space'>
						<div className='pdv-10 mh-14 border-bottom-main flex flex-center flex-gap-10'>
							<Input readOnly label='Año Fiscal' value={`${picture.hole?.fiscalYear}`} type='secondary' size='large' style={{width: 120}} />
							<Input readOnly label='HoleId' value={picture.holeId} type='secondary' size='large' style={{width: 300}} />
							<InputNumber readOnly label='Desde' value={picture.from} type='secondary' size='large' style={{width: 120}} />
							<InputNumber readOnly label='Hasta' value={picture.to} type='secondary' size='large' style={{width: 120}} />
						</div>
						<div className='flex flex-center flex-gap-10 text-16 pdh-14'>
							<p className='text-secondary'>Realizadas hoy:</p>
							<span className='bold'>{count}</span>
						</div>
					</div>
					<Section className='flex-1 o-hidden relative' rounded={false} bordered={false}>
						<div className='cover flex flex-middle'>
							<div ref={wrapperImage} className='relative'>
								<img
									className='hole-image'
									alt='holeImage'
									onLoad={handleLoadImage}
									src={`${apiUrl}/picture/image/${picture.pictureId}`}
									onClick={handleImageClick} />
								{point && <Point point={point} />}
								{tempPosition && (
									<ImageRect
										parentRef={wrapperImage}
										position={tempPosition}
										index={-1}
										onRemove={handleRemoveRect}
										onUpdate={handleUpdateRect} />
								)}
								{positions.map((position, index) => (
									<ImageRect
										key={`${position.x0}-${position.x1}-${index}`}
										parentRef={wrapperImage}
										position={position}
										index={index}
										onRemove={handleRemoveRect}
										onUpdate={handleUpdateRect} />
								))}
							</div>
						</div>
					</Section>
				</Card>
				<ImagePreview image={image} positions={positions} />
			</div>
			<div className='flex flex-center flex-gap-14 flex-space'>
				<Button label='Limpiar Cortes' disabled={positions.length === 0} onClick={clean} bold size='large' className='pdh-20' />
				<div className='flex flex-center flex-gap-14'>
					<Button type='warning' label='Omitir Imagen' onClick={handleOmit} bold size='large' className='pdh-20' />
					<Button label='Finalizar' onClick={handleFinish} bold type='assertive' size='large' className='pdh-20' />
				</div>
			</div>
		</StyledImageCut>
	);
};
